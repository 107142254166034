import React from "react";
import CardPlan from "components/PlanView/components/CardPlan";
import { ListWrapper, List, Item, TextWrapper } from "./styles";
import { MdCheckCircle } from "react-icons/md";

const CustomItem = ({ children }) => (
  <Item>
    <MdCheckCircle /> <span>{children}</span>
  </Item>
);

function CardYearly() {
  // const { data } = usePlan();
  return (
    <CardPlan
      title={
        <>
          Plano Anual <small>(20% desconto)</small>
        </>
      }
      price={"79,92"}
    >
      <TextWrapper>
        <p>Pagamento Anual de R$ 959,04</p>
        <p>dividido em 4x no boleto ou 5x no cartão.</p>
      </TextWrapper>

      <ListWrapper>
        <div>
          <h4>Economize e ainda ganhe:</h4>
          <List>
            <CustomItem>01 hora técnica de design</CustomItem>
            <CustomItem>Treinamento personalizado</CustomItem>
            <CustomItem>Até 01 ano grátis de Imovelweb***</CustomItem>
          </List>
        </div>
      </ListWrapper>
    </CardPlan>
  );
}

export default CardYearly;
