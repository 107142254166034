import styled from "styled-components";

export const ListWrapper = styled.div`
  display: flex;
  justify-content: center;

  h4 {
    margin-bottom: 5px;
  }
`;
export const List = styled.ul``;
export const Item = styled.li`
  padding: 4px 0;
  font-weight: 400;

  svg {
    vertical-align: middle;
    margin-top: -2px;
    color: ${(p) => p.theme.colors.success};
  }
`;
export const TextWrapper = styled.div`
  padding: 27px 0;
`;
